<template>
    <div class="top-destinations-section">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <GeneralMinTitle
                        :title="$t('homePage.topDestinations.title')"
                        :prag="$t('homePage.topDestinations.subtitle')"
                    />
                </div>
            </div>
            <div class="row" v-if="destinationsLoading">
                <div class="col-md-12">
                    <Skeleton width="100%" height="15rem" class="mx-auto"></Skeleton>
                </div>
            </div>
            <div class="row" v-else>
                <div class="col-md-12">
                    <Swiper
                        :slides-per-view="1"
                        slidesPerView="1.2"
                        :slidesPerView="'auto'"
                        :dir="rtl"
                        :autoplay="{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }"
                        :pagination="{
                            clickable: true,
                            enabled: false,
                            hiddenClass: 'swiper-pagination-hidden',
                        }"
                        :navigation="{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }"
                        :modules="[SwiperPagination, SwiperAutoplay]"
                        :parallax="true"
                        spaceBetween="0"
                        pagination
                        :breakpoints="{
                            '442': {
                                slidesPerView: 1.2,
                                pagination: {
                                    enabled: false,
                                    hiddenClass: 'swiper-pagination-hidden',
                                },
                            },
                            '640': {
                                slidesPerView: 1.2,
                                pagination: {
                                    enabled: false,
                                    hiddenClass: 'swiper-pagination-hidden',
                                },
                            },
                            '768': {
                                slidesPerView: 2,
                                pagination: {
                                    enabled: true,
                                    hiddenClass: 'swiper-pagination-hidden',
                                },
                            },
                            '993': {
                                slidesPerView: 2,
                                pagination: {
                                    enabled: true,
                                    hiddenClass: 'swiper-pagination-hidden',
                                },
                            },
                            '1024': {
                                slidesPerView: 3,
                                pagination: {
                                    enabled: true,
                                    hiddenClass: 'swiper-pagination-hidden',
                                },
                            },
                        }"
                    >
                        <swiper-slide v-for="item in destinationsWithLogos" :key="item.id">
                            <div class="destinations-card">
                                <img :src="item.image" alt="" class="img-fluid w-100" />
                                <div class="content-box">
                                    <h4 class="name">
                                        <LocLink @click.prevent="searchByDestination(item)">
                                            {{ item.name }}
                                        </LocLink>
                                    </h4>
                                    <LocLink @click.prevent="searchByDestination(item)">
                                        <div class="arrow-box">
                                            <i class="pi pi-arrow-left" v-if="rtl"></i>
                                            <i class="pi pi-arrow-right" v-else></i>
                                        </div>
                                    </LocLink>
                                </div>
                            </div>
                        </swiper-slide>
                    </Swiper>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useCheckCategory } from '../../../../libs/shared/composables/useCheckCategory';
import { useCommonStore } from '../../../../libs/shared/store/search/common';
import { useCountriesStore } from '../../../../libs/shared/store/countries';
import { useRouter } from 'vue-router';

const { categoryFilterCountries, getCategoryList, getCategoryCourses, categoryFilterClear } = useCheckCategory();
const countriesStore = useCountriesStore();
const { destinationsWithLogos, destinationsLoading } = storeToRefs(countriesStore);

const commonStore = useCommonStore();
const { category, preventClear } = storeToRefs(commonStore);
const router = useRouter();

const searchByDestination = item => {
    // Clear filters
    categoryFilterClear.value();

    category.value = item.is_university ? 'university' : 'institute';
    if (!categoryFilterCountries.value.some(selected => selected.id === item.id)) {
        categoryFilterCountries.value.push({ id: item.id, text: item.name, type: 'country' });
    }
    // call APIs
    preventClear.value = true;
    getCategoryList.value();
    getCategoryCourses.value();

    router.push(`/${category.value}`);
};
</script>

<script>
import destinationLink from '@/mixins/destinationLink';
export default {
    mixins: [destinationLink],

    computed: {
        rtl() {
            return this.$i18n.locale == 'ar';
        },
    },
};
</script>

<style lang="scss">
.top-destinations-section {
    .swiper {
        padding: 0 48px;
    }

    .swiper-pagination-hidden {
        display: none;
    }
}
</style>
<style lang="scss" scoped>
.swiper-button-next {
    right: 0;

    @include dir(rtl) {
        left: 0;
        right: unset;
    }
}

.swiper-button-prev {
    left: 0;

    @include dir(rtl) {
        right: 0;
        left: unset;
    }
}

.swiper-button-prev,
.swiper-button-next {
    height: 99%;
    top: 50%;
    padding: 2px;
    width: 56px;
    transform: translateY(-43%);
    background-color: #fff;

    &:after {
        display: none;
    }

    i {
        height: 37px;
        min-width: 37px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 1px 2px 10px #33333350;
        display: grid;
        place-content: center;
    }

    .pi {
        font-size: 22px;
    }
}

@include media(772) {
    .top-destinations-section {
        .swiper {
            padding: 0;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: none;
    }
}
</style>
