<template>
    <form
        class="form-box d-flex float-box row"
        @submit.prevent="searchBy(getCountry(selectedCountry), selectedCategory, selectedCourse), validate()"
    >
        <div class="form-group col-12 col-md-12 col-lg-3">
            <label for="">
                {{ $t('homePage.wheretostudy.country') }}
            </label>
            <UtilsGenericDropDown
                v-model="selectedCountry"
                :options="destinationsWithLogos"
                :placeholder="`${$t('choose')} ${$t('country')}`"
                close-on-scroll
                option-label="name"
                option-value="id"
                @change="(categories = []), (categoriesPagination.page = 0), (selectedCategory = null), getCategories()"
            />
            <span v-if="emptyDestination" class="text-danger text-sm">
                {{ $t('homePage.wheretostudy.selectAnotherDestination') }}
            </span>
        </div>
        <div class="form-group col-12 col-md-12 col-lg-4">
            <label for="">
                {{ $t('homePage.wheretostudy.university') }}
            </label>
            <UtilsGenericDropDown
                v-model="selectedCategory"
                :loading="univLoading"
                :on-empty="$t('homePage.wheretostudy.chooseCountryFirst')"
                :options="categories"
                :placeholder="`${$t('choose')} ${$t(category)}`"
                :virtual-scroller-options="{
                    itemSize: 50,
                    lazy: true,
                    onLazyLoad: e => handleLazyLoad(e, getCategories),
                }"
                close-on-scroll
                text-truncate-width="200px"
                @change="(courses = []), (coursesPagination.page = 0), (selectedCourse = null), getCourses()"
            />
            <span v-if="emptyUniversity" class="text-danger text-sm">
                {{ $t('homePage.wheretostudy.selectAnotherUniversity') }}
            </span>
        </div>
        <div class="form-group col-12 col-md-12 col-lg-4">
            <label for="">
                {{ $t('homePage.wheretostudy.course') }}
            </label>
            <UtilsGenericDropDown
                v-model="selectedCourse"
                :loading="coursesLoading"
                :on-empty="$t('homePage.wheretostudy.chooseUniversityFirst')"
                :options="courses"
                :placeholder="`${$t('homePage.wheretostudy.chooseCourse')}`"
                :virtual-scroller-options="{
                    itemSize: 50,
                    lazy: true,
                    onLazyLoad: e => handleLazyLoad(e, getCourses),
                }"
                close-on-scroll
                text-truncate-width="200px"
                @change="courseError = false"
            />
        </div>
        <div class="form-group col-12 col-md-12 col-lg-1">
            <Button class="search-btn" type="submit">
                <span>{{ $t('search.searchText') }}</span>
                <i class="pi pi-search"></i>
            </Button>
        </div>
    </form>
</template>

<script setup>
import { useCheckCategory } from '../../../../libs/shared/composables/useCheckCategory';
import { useCommonStore } from '../../../../libs/shared/store/search/common';
import { useCountriesStore } from '../../../../libs/shared/store/countries';
const { categoryFilterClear, categoryFilterCountries, categoryFilterUniversitiesOrInstitutes } = useCheckCategory();
const commonStore = useCommonStore();
const { activeTab, category, preventClear } = storeToRefs(commonStore);
const router = useRouter();

const countriesStore = useCountriesStore();
const { destinationsWithLogos } = storeToRefs(countriesStore);

const searchBy = (country, selectedCategory, course) => {
    categoryFilterClear.value();
    activeTab.value = 1;

    if ((country || selectedCategory) && !course) {
        if (country) {
            if (!categoryFilterCountries.value.some(selected => selected.id === country.id)) {
                categoryFilterCountries.value.push({ id: country.id, text: country.name, type: 'country' });
            }
        }
        if (selectedCategory) {
            if (!categoryFilterUniversitiesOrInstitutes.value.some(selected => selected.id === selectedCategory.id)) {
                categoryFilterUniversitiesOrInstitutes.value.push({
                    id: selectedCategory.id,
                    text: selectedCategory.name,
                    type: 'category',
                });
            }
        }
        preventClear.value = true;
        if (country) {
            router.push(`/university`);
        } else {
            const destination = selectedCategory.is_university ? 'university' : 'institute';
            router.push(`/${destination}`);
        }
    } else if (course) {
        const destination = selectedCategory.is_university ? 'university' : 'institute';
        router.push(`/${destination}/course/${course.id}`);
    }
};

const getCountry = selectedCountry => {
    if (selectedCountry) {
        return destinationsWithLogos.value.find(country => country.id === selectedCountry);
    }
    return null;
};
</script>

<script>
import { useAxios } from '@/composables/useAxios';

export default {
    data() {
        return {
            coursesLoading: false,
            univLoading: false,
            emptyDestination: false,
            emptyUniversity: false,
            selectedCountry: null,
            countryError: false,
            selectedCountriesList: [],
            category: 'homePage.wheretostudy.university',
            categories: [],
            page: 0,
            total: 9,
            selectedCategory: null,
            categoryError: false,
            selectedCategoriesList: [],
            selectedCourse: null,
            courseError: false,
            courses: [],
            categoriesPagination: {
                page: 0,
                total: 9,
            },
            coursesPagination: {
                page: 0,
                total: 9,
            },
            categoriesLoading: false,
            previousCategoriesLast: 0,
            previousCoursesLast: 0,
        };
    },
    computed: {
        countriesIds() {
            return this.selectedCountriesList.map(el => `country_ids[]=${el.id}`).join('&');
        },
    },
    methods: {
        handleLazyLoad(e, loadFunction) {
            let previousLast =
                loadFunction === this.getCategories ? this.previousCategoriesLast : this.previousCoursesLast;

            if (e.last > previousLast) {
                if (loadFunction === this.getCategories) {
                    this.previousCategoriesLast = e.last;
                } else {
                    this.previousCoursesLast = e.last;
                }
                loadFunction();
            }
        },
        validate() {
            if (!this.selectedCountry) {
                this.emptyDestination = true;
            }
        },
        async getCategories() {
            this.univLoading = true;
            this.emptyDestination = false;
            if (!this.selectedCountry) {
                this.categoriesPagination.page = 0;
                this.selectedCategory = null;
                this.categories = [];
                this.courses = [];
            }
            let q = '';
            if (
                this.selectedCountry &&
                this.categories.length < this.categoriesPagination.total &&
                !this.categoriesLoading
            ) {
                this.categoriesPagination.page += 1;
                q = `page=${this.categoriesPagination.page}`;
                q += '&country_id[]=';
                q += this.selectedCountry;
                const config = useRuntimeConfig();
                this.page += 1;
                const { api } = useAxios();
                this.categoriesLoading = true;
                await api
                    .get(`${config.public.baseURL}/universities-institutes?per_page=9&${q}`)
                    .then(res => {
                        if (res.data.data.length > 0) {
                            this.categories.push(...res.data.data);
                            this.categoriesPagination.total = res.data.pagination.total;
                        }
                    })
                    .catch(() => {})
                    .finally(() => {
                        this.categoriesLoading = false;
                    });
                if (this.categories.length == 0) {
                    this.emptyDestination = true;
                }
            }
            this.univLoading = false;
        },
        async getCourses() {
            this.coursesLoading = true;
            this.categoryError = false;
            this.emptyUniversity = false;
            if (!this.selectedCategory) {
                this.coursesPagination.page = 0;
                this.selectedCourse = null;
                this.courses = [];
                this.coursesLoading = false;
            }
            // this.courses = [];
            if (this.selectedCategory?.is_university && this.courses.length < this.coursesPagination.total) {
                this.coursesPagination.page += 1;
                let q = `page=${this.coursesPagination.page}`;
                q += `&filter[universities]=${this.selectedCategory.id}`;
                const config = useRuntimeConfig();
                const { api } = useAxios();
                await api
                    .get(`${config.public.baseURL}/university-courses?per_page=9&${q}`)
                    .then(res => {
                        if (res.data.data.length > 0) {
                            this.courses.push(...res.data.data);
                            this.coursesPagination.total = res.data.meta.total;
                        }
                    })
                    .catch(error => {})
                    .finally(() => {
                        this.coursesLoading = false;
                    });
            } else if (!this.selectedCategory?.is_university && this.courses.length < this.coursesPagination.total) {
                this.coursesPagination.page += 1;
                let q = `page=${this.coursesPagination.page}`;
                if (this.selectedCategory?.id) q += `&filter[institutes]=${this.selectedCategory.id}`;
                const config = useRuntimeConfig();
                const { api } = useAxios();
                await api
                    .get(`${config.public.baseURL}/english-courses?per_page=9&${q}`)
                    .then(res => {
                        if (res.data.data.length > 0) {
                            this.courses.push(...res.data.data);
                            this.coursesPagination.total = res.data.meta.total;
                        }
                    })
                    .catch(error => {})
                    .finally(() => {
                        this.coursesLoading = false;
                    });
            }

            if (this.courses.length == 0 && this.selectedCategory) {
                this.emptyUniversity = true;
            }
            this.coursesLoading = false;
        },
        addCountry() {
            this.countryError = false;
            if (!this.selectedCountriesList.includes(this.selectedCountry)) {
                this.selectedCountriesList.push(this.selectedCountry);
                this.categories = [];
                this.getCategories();
            }
            this.selectedCountry = null;
            this.clearHandler(this.category);
        },
        clearHandler(key) {
            switch (key) {
                case 'country':
                    this.selectedCountriesList = [];
                    this.selectedCitiesList = [];
                    this.selectedCategoriesList = [];
                    this.categories = [];
                    break;
                case this.category:
                    this.selectedCategoriesList = [];
                    break;
                case 'dates':
                    this.selectedDatesList = [];
                    break;
                case 'courseName':
                    this.courseName = '';
                    break;

                default:
                    break;
            }
        },
        getFilteredIds(list, key, item) {
            const filtered = list.filter(el => el.id != item.id);
            return filtered.map(el => `${key}_ids[]=${el.id}`).join('&');
        },
        async removeItem(key, item) {
            switch (key) {
                case 'country':
                    this.selectedCountriesList = this.selectedCountriesList.filter(el => el.id != item.id);
                    const filteredCountriesIds = this.getFilteredIds(this.selectedCountriesList, key, item);
                    if (filteredCountriesIds == '') {
                        this.clearHandler(key);
                    }
                    break;
                case 'city':
                    const filteredCitiesIds = this.getFilteredIds(this.selectedCitiesList, key, item);
                    if (filteredCitiesIds == '') {
                        this.clearHandler(key);
                    } else {
                        this.clearCategoriesOnCitiesUpdate(this.countriesIds, filteredCitiesIds, item);
                    }
                    break;
                case this.category:
                    this.selectedCategoriesList = this.selectedCategoriesList.filter(el => el.id != item.id);
                    break;
                case 'dates':
                    this.selectedDatesList = this.selectedDatesList.filter(el => el.id != item.id);
                    break;

                default:
                    break;
            }
        },

        setData(payload) {
            this.$store.dispatch('searchParams/setData', payload);
        },
    },
};
</script>

<style lang="scss">
.p-dropdown .p-dropdown-clear-icon {
    @include dir(rtl) {
        right: unset;
        left: 2.5rem;
    }
}

.form-group {
    label {
        margin-bottom: 5px;
    }

    .search-btn {
        margin-left: auto;

        span {
            display: none;
        }

        @media (max-width: 992px) {
            width: 100%;
            span {
                display: block;
            }
            i {
                display: none;
            }
        }
    }

    @media (max-width: 992px) {
        margin-bottom: 20px;
    }
}

.float-box {
    min-width: 60vw;
    margin-left: -20vw;
    background-color: #fff;

    @include dir(rtl) {
        margin-left: unset;
        margin-right: -20vw;
    }

    @media (max-width: 992px) {
        margin-left: unset;

        @include dir(rtl) {
            margin-right: unset;
        }

        width: 100%;
    }
}

.search-btn {
    background-color: #f25029;
    border-radius: 15px;
    width: 74px;
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
        color: #fff;
        font-size: 24px;
    }
}
</style>
