<template>
    <div class="onboarding-intro">
        <h2 class="title">{{ $t('onboarding.onboardingIntro.introTitle') }}</h2>
        <p class="subtitle">{{ $t('onboarding.onboardingIntro.introSubtitle') }}</p>
        <nuxt-link class="link" :to="'/onboarding'">
            <img src="/assets/img/ai.png" alt="AI Icon" />
            <span>{{ $t('onboarding.onboardingIntro.introButton') }}</span>
        </nuxt-link>
    </div>
</template>

<style lang="scss" scoped>
.onboarding-intro {
    padding: toRem(25);
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: $color-primary;
    .title {
        font-size: toRem(18);
        font-weight: 600;
        color: white;
        margin-bottom: toRem(16);
    }
    .subtitle {
        font-size: toRem(14);
        font-weight: 600;
        color: $gray-8;
        margin-bottom: toRem(24);
    }
    .link {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: toRem(16);
        padding: toRem(8) toRem(25);
        border-radius: toRem(5);
        background-color: white;
        img {
            width: toRem(27);
        }
        span {
            font-size: toRem(16);
            font-weight: 700;
            color: $color-primary;
        }
    }
}

@media (min-width: $sm) {
    .onboarding-intro {
        padding: toRem(32);
        .title {
            font-size: toRem(36);
            margin-bottom: toRem(24);
        }
        .subtitle {
            font-size: toRem(16);
            margin-bottom: toRem(27);
        }
    }
}

@media (min-width: $lg) {
    .onboarding-intro {
        padding: toRem(56);
        .title {
            font-size: toRem(64);
        }
        .subtitle {
            font-size: toRem(27);
            width: toRem(950);
            margin-bottom: toRem(40);
        }
        .link {
            padding: toRem(8) toRem(40);
            img {
                width: toRem(45);
            }
            span {
                font-size: toRem(20);
            }
        }
    }
}
</style>

<script setup></script>
